import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-info',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './modal-info.component.html',
})
export class ModalInfoComponent {
  public activeModal = inject(NgbActiveModal);

  @Input() public title: string = '';
  @Input() public body?: string;
  public redirects: boolean = false;
}
