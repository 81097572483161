<div class="modal-body d-flex flex-column justify-content-center align-items-center">
  <p *ngIf="this.title" class="text-content-primary fw-semibold fs-4 text-center">
    {{ this.title }}
  </p>
  <p *ngIf="this.body" class="text-content-primary fw-semibold fs-5 text-center">
    {{ this.body }}
  </p>
  <button type="button" class="btn btn-oxxo-red rounded-5" (click)="activeModal.close('Close click')"
  [routerLink]="redirects ? '/mis-inmuebles' : null">
    Aceptar
  </button>
</div>
